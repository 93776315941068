<template>
  <el-dialog title="账号余额" :visible.sync="visible" @close="closeDialog" class="table">
    <el-row>
      <el-button type="text" class="font-info" icon="el-icon-user">会员等级:</el-button> <i class="level"> {{userLevelId}}</i>
    </el-row>
    <el-row>
      <el-button type="text" class="font-info" icon="el-icon-coin">账号余额:</el-button>  <i class="balance"> {{balance}} </i>元
    </el-row>
  </el-dialog>
</template>

<script>
import {getSessionUser} from '../../../api/auth'
export default {
  props: ["dialogVisible"],
  watch: {
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val) this.getUserList();
      },
      deep: true
    }
  },
  data() {
    return {
      visible: false,
      balance:0,
      userLevelId:'无',
    };
  },
  methods: {
    getUserList(){
      getSessionUser({}).then((result) => {
        if(result!=null){
          this.$store.dispatch("setUserInfo", result);
          this.balance=result.blance;
          //TODO 判断，对应的会员等级
            if(result.userLevelId!=null) {//是否已是vip
              //vip未过期
              if (new Date() <= result.vipEndTime) {
                if(result.userLevelId==2){
                  this.userLevelId='标准会员';
                } else if(result.userLevelId==4){
                  this.userLevelId='高级会员';
                } else if(result.userLevelId==5){
                  this.userLevelId='企业会员';
                }
              }
            }
        }
      }).catch((error) => {
        console.log(error);
      })
    },
    linkOverseas(row) {
      window.open(row.enUrl);
    },
    linkDomestic(row) {
      window.open(row.zhUrl);
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .font-info {
      color: #3e3e3e;
    }
  .font-warning{
    color: #E6A23C;
  }
  .level{
    font-size: 12px;
    color: #E6A23C;
    margin-top: 6px;
    font-size: 24px;
  }
  .balance{
    font-size: 12px;
    color: #409EFF;
    margin-top: 6px;
    font-size: 24px;
  }
</style>
