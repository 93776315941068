<template>
  <div>
    <Tab :tablist="list" @change="changeTab" @constDetail="openConstDetailPop" @accountDetail="openAccountDetailPop" @serverDialog="openServerDialog" :tabCurrent="current" />
    <Content
      v-for="(item,index) in list"
      v-if="index == current"
      :key="index"
      :tabContent="item"
      :isSearching="isSearching"
      @search="searchId"
    />
    <!-- <Table :data="tableDatas" :sureflag="isSureDownload" :downflag="isDownloading" :clearflag="isClearFlag" v-if="showTable" v-loading="tableLoading"
           @noBanlance="noBanlances" @onDownload="downloadId" @onSureDown="changeDown" /> -->
    <Table :data="tableDatas" :sureflag="isSureDownload" :downflag="isDownloading" :clearflag="isClearFlag" :netError="isNetError" v-if="showTable" v-loading="tableLoading"
            @noBanlance="noBanlances" @onDownload="downloadId" @onSureDown="changeDown" />
    <Dialog :dialogVisible="dialogTableVisible" @closeDialog="closeConstDetailPop" />
    <Accountdialog :dialogVisible="accountDialogTableVisible" @closeDialog="closeAccountDetailPop" />
    <Serverdialog :dVisible="serverDialogVisible" @closeDialogs="closeServerDialod" />
  </div>
</template>

<script>
import Tab from "./module/tab.vue";
import Table from "./module/table.vue";
import Content from "./module/content.vue";
import Dialog from "./module/dialog.vue";
import { searchImage,getShutterStatus } from "../../api/downLoad";
import { message } from '@/utils/resetMessage';
import Accountdialog from "./module/accountdialog";
import Serverdialog from "./module/serverdialog";
import crypto from "crypto";

export default {
  components: {
    Accountdialog,
    Tab,
    Table,
    Content,
    Dialog,
    Serverdialog
  },
  data() {
    return {
      getAll_status:0, //状态
      isLoggedIn:false,
      current: 0,
      webType: "shutterstock_V2",
      list:[
        {
          title: "SS/Hai",
          subTitle: "SS/Hai",
          titleState: "success",
          tip:'',
          label: null,
          webType: "shutterstock_V2",
          isPop:false,
          icon: require("@/assets/images/download/ic1_1.png"),
          status:true
        },
        {
          title: "AdobeStock",
          subTitle: "",
          titleState: "success",
          label: null,
          isPop:false,
          webType: "adobestock",
          icon: require("@/assets/images/download/ic8_8.png"),
          status:true
        },
        {
          title: "iStockphoto",
          subTitle: "",
          titleState: "success",
          label: null,
          webType: "istockphoto",
          isPop:false,
          icon: require("@/assets/images/download/ic5_5.png"),
          status:true
        },
        {
          title: "Depositphotos",
          subTitle: "",
          titleState: "success",
          label: null,
          isPop:false,
          webType: "depositphotos",
          isPop:false,
          icon: require("@/assets/images/download/ic2_2.png"),
          status:true
        },
        {
          title: "Dreamstime",
          subTitle: "",
          titleState: "success",
          label: null,
          isPop:false,
          webType: "dreamstime",
          icon: require("@/assets/images/download/ic6_6.png"),
          status:true
        },
        {
          title: "123rf",
          subTitle: "",
          titleState: "success",
          label: null,
          isPop:false,
          webType: "rfoto",
          icon: require("@/assets/images/download/ic3_3.png"),
          status:true
        },
        {
          title: "vectorstock",
          subTitle: "",
          titleState: "success",
          label: null,
          webType: "vectorstock",
          isPop:false,
          icon: require("@/assets/images/download/ic11_11.png"),
          status:true
        },
        {
          title: "freepik",
          subTitle: "",
          tip:"<ul><li>Freepik请复制图片编号下载，编号为网址栏.htm前面的数字</ul></li>",
          titleState: "success",
          label: null,
          webType: "freepik",
          isPop:false,
          icon: require("@/assets/images/download/ic10_10.png"),
          status:true
        },
        {
          title: "Alamy",
          subTitle: "",
          tip:"<ul><li>Alamy请复制网站页面上的图片ID下载，如：Image ID: D2I95C</li><li>Alamy仅提供JPG格式，无法提供矢量格式，请按需下载</li></ul>",
          titleState: "success",
          label: null,
          isPop:false,
          webType: "alamy",
          icon: require("@/assets/images/download/alamy.png"),
          status:true
        },
        {
          title: "Pixelsquid",
          subTitle: "",
          tip:"<ul><li>pixelsquid请复制网站页面上的ID下载，如：https://www.pixelsquid.com/png/retro-car-white-2337168235838838579，则2337168235838838579是素材ID，输入素材ID进行下载</li></ul>",
          titleState: "success",
          label: null,
          webType: "pixelsquid",
          isPop:false,
          icon: require("@/assets/images/download/pixelsquid.png"),
          status:true
        },
        {
          title: "SS/Hai视频",
          subTitle: "",
          titleState: "success",
          tip:"SS/Hai视频下载目前仅支持HD普通视频下载，如需严选或4K视频请联系客服下载",
          label: null,
          webType: "video",
          icon: require("@/assets/images/download/ic1_1.png"),
          status:true
        }
      ],
      tableDatas: [],
      showTable: false, //是否显示表格
      tableLoading: false, //加载数据效果
      dialogTableVisible: false, //费用明细弹窗
      accountDialogTableVisible: false, //余额弹窗
      serverDialogVisible: false, //客服弹窗
      form: {
        webType: "",
        imageID: ""
      },
      dataForm:{
        img: '', //缩略图
        id: '', //图片ID
        imgSize: "", //图片大小
        complete: false, //操作状态为按钮
        loading: false, //操作状态为加载
        saleType: "", //默认为空
        price: 0, //图片价格
        priceList:[], //视频价格
        videoType:0,//视频格式
        isfk:false,//是否4k
        isyx:false,//是否严选
        orgPrice:'',//原价
        balance: "", //用户余额
        value: 4, //图片类型默认值
        disabled: false, //是否可选
        videoValue: 0, //视频类型默认值
        videoLabel:'', //视频标签
        videoDisabled: false, //是否可选
        videoOptions: [], //视频类型
        options: [], //图片类型
        webType: this.webType, //网站类型
        downurl: "", //主下载链接
        downurl2: "", //备用下载链接
        type : true, //搜索ID是否正常
        msg:'', //提示消息
        showType:true, //是否显示格式
        error:false //下载是否出错
      },
      timer:null, //定时器
      isSearching:false, // 是否在搜索
      searchCount:0, //搜索数量
      isDownloading:false, // 是否正在下载
      isSureDownload:false, // 是否确认下载
      isClearFlag:false, //是否需要清除Url
      isNetError: false //是否网络通畅标记
    };
  },
  mounted() {
    this.isLoggedIn = (this.$store.state.user.token !== null || this.$store.state.user.token !== "")
    && (this.$store.state.user.loginNameType!=null )? true : false;
    this.getStatus();

    let userName="",userEmail="";
    if(this.$store.state.common.userInfo.mobile){
      userName=this.$store.state.common.userInfo.mobile;
    }
    if(this.$store.state.common.userInfo.mail){
      userEmail=this.$store.state.common.userInfo.mail;
    }

    userName=userName?userName:userEmail; //为空则赋一样的值
    userEmail=userEmail?userEmail:userName+"@taogetu.com";//为空需要加邮箱后缀，否则无法修改用户

    //其余代码
    tawks0.parentNode.insertBefore(tawks1,tawks0);
    tawks1.async=true;
    tawks1.src='https://embed.tawk.to/5e883faf69e9320caac026bc/default';
    tawks1.charset='UTF-8';
    tawks1.setAttribute('crossorigin','*');

    //console.log("tel", tawks0);
    //console.log("tel", userName);
    //console.log("mail", userEmail);
    //对用户名称进行加密，才能更新
    var hashValue = crypto.createHmac('sha256','7350def148c0aed56aa1e493200c75a5a575a359').update(userEmail).digest('hex');
    //console.log("test111 mounted->hashValue:",hashValue);

    Tawk_API=Tawk_API||{};

    Tawk_API.visitor = {
      name : userName,
      email : userEmail,
      hash : hashValue
    };

    Tawk_LoadStart=new Date();

    // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    // s1.async=true;
    // s1.src='https://embed.tawk.to/5e883faf69e9320caac026bc/default';
    // s1.charset='UTF-8';
    // s1.setAttribute('crossorigin','*');
    // s0.parentNode.insertBefore(s1,s0);
  },
  beforeDestroy(){

  },
  methods: {
    //获取tab列表
    getStatus() {
      getShutterStatus().then(res=>{
        let data = JSON.parse(res);
        if(data.adobestock=="online"){
          this.list[1].status = true;
        }else{
          this.list[1].status = false;
        }
        if(data.istockphoto=="online"){
          this.list[2].status = true;
        }else{
          this.list[2].status = false;
        }
        if(data.depositphotos=="online"){
          this.list[3].status = true;
        }else{
          this.list[3].status = false;
        }
        if(data.dreamstime=="online"){
          this.list[4].status = true;
        }else{
          this.list[4].status = false;
        }
        if(data[`123RF`]=="online"){
          this.list[5].status = true;
        }else{
          this.list[5].status = false;
        }
        if(data.vectorstock=="online"){
          this.list[6].status = true;
        }else{
          this.list[6].status = false;
        }
        if(data.Alamy=="online"){
          this.list[8].status = true;
        }else{
          this.list[8].status = false;
        }
        if(data.pixelsquid=="online"){
          this.list[9].status = true;
        }else{
          this.list[9].status = false;
        }
        // this.list[0].status = true;
        // this.list[10].status = true;
      }).catch(()=>{

      })
      this.timer = setTimeout(() => {
        getShutterStatus().then(res=>{
          let data = JSON.parse(res);
          if(data.adobestock=="online"){
            this.list[1].status = true;
          }else{
            this.list[1].status = false;
          }
          if(data.istockphoto=="online"){
            this.list[2].status = true;
          }else{
            this.list[2].status = false;
          }
          if(data.depositphotos=="online"){
            this.list[3].status = true;
          }else{
            this.list[3].status = false;
          }
          if(data.dreamstime=="online"){
            this.list[4].status = true;
          }else{
            this.list[4].status = false;
          }
          if(data[`123RF`]=="online"){
            this.list[5].status = true;
          }else{
            this.list[5].status = false;
          }
          if(data.vectorstock=="online"){
            this.list[6].status = true;
          }else{
            this.list[6].status = false;
          }
          if(data.Alamy=="online"){
            this.list[8].status = true;
          }else{
            this.list[8].status = false;
          }
          if(data.pixelsquid=="online"){
            this.list[9].status = true;
          }else{
            this.list[9].status = false;
          }
        }).catch(()=>{

        })
      }, 60000);
    },
    //切换tab
    changeTab(index) {
      this.current = index;
      this.webType = this.list[this.current].webType;
      this.tableDatas = [];
      this.isSearching = false;
      this.isSureDownload = false;// 是否确认下载
      this.showTable = false; //是否显示表格
      this.tableLoading = false; //加载数据效果
      this.dialogTableVisible = false; //费用明细弹窗
      this.accountDialogTableVisible = false; //余额弹窗
    },
    //打开客服下载提示
    openServerDialog(){
      this.serverDialogVisible = true;
    },
    closeServerDialod(){
      this.serverDialogVisible = false;
    },
    //费用明细
    openConstDetailPop() {
      this.dialogTableVisible = true;
    },
    closeConstDetailPop() {
      this.dialogTableVisible = false;
    },
    //余额明细
    openAccountDetailPop() {
      this.accountDialogTableVisible = true;
    },
    closeAccountDetailPop() {
      this.accountDialogTableVisible = false;
    },
    //获取表格数据
    getTableDatas(result) {
      this.dataForm = {
        img: '', //缩略图
        id: '', //图片ID
        imgSize: "", //图片大小
        complete: false, //操作状态为按钮
        loading: false, //操作状态为加载
        saleType: "", //默认为空
        price: 0, //图片价格
        priceList:[], //视频价格
        videoType:0,//视频格式
        isfk:false,//是否4k
        isyx:false,//是否严选
        orgPrice:'',//原价
        balance: "", //用户余额
        value: 4, //图片类型默认值
        disabled: false, //是否可选
        videoValue: 0, //视频类型默认值
        videoLabel:'', //视频标签
        videoDisabled: false, //是否可选
        videoOptions: [], //视频类型
        options: [], //图片类型
        webType: this.webType, //网站类型
        isVideo:false, //是否视频
        downurl: "", //主下载链接
        downurl2: "", //备用下载链接
        downurl3: "", //海外下载链接
        type : true, //正常
        showType:true,//是否显示类型
        msg:'', //提示消息
        isBuy:false, //是否购买过
        isCos:false, //是否腾讯云
        isDownload:false,//是否下载中
        isDownload1:false //是否备用下载中
      }
      //请求成功后赋值，并且去掉加载效果
      if(result.status==0){
        if(this.webType != 'shutterstock' && this.webType != 'shutterstock_V2'){
          this.dataForm.img = result.iconUrl;
        }

        this.dataForm.id = result.recCode;
        this.dataForm.price = result.price;
        this.dataForm.orgPrice=result.orgPrice;
        this.dataForm.isVideo = false;
        
        if (result.searchDTO.picType == 0) { //0为jpg
          this.dataForm.imgSize =result.detailJson[0].px;
          this.dataForm.value = 0;
          this.dataForm.options = [
            {value: 0, label: "JPG"}
          ];
        } else if(result.searchDTO.picType == 4) {//4为eps
          this.dataForm.value = 4;
          this.dataForm.options = [
            {value: 4, label: "EPS"}
          ];
        }else if(result.searchDTO.picType == 9){ 
          this.dataForm.showType = false;
        }
        this.dataForm.isCos = result.detailJson[0].isCos;
        //设置是否购买
        this.dataForm.isBuy = result.detailJson[0].isBuy;
        if(this.dataForm.isBuy){
          this.dataForm.msg = '免费';
        }
        //视频搜索
        if(this.webType.indexOf('video') != -1 || this.webType.indexOf('vedio') != -1){
           this.dataForm.isVideo = true;
           this.dataForm.value = result.searchDTO.picType;
           this.dataForm.priceList = result.priceList;
           if(result.searchDTO.picType == 5){
             this.dataForm.options = [{value: 5, label: "MOV"}];
           }else{
             this.dataForm.options = [{value: 6, label: "MP4"}];
           }
           this.dataForm.isyx = result.detailJson[0].yxflag;
           this.dataForm.isfk = result.detailJson[0].fkflag;
           this.dataForm.videoType = result.videoType;
           switch( this.dataForm.videoType){
           case 0:
             this.dataForm.videoOptions = [{value: 0, label: "HD"}];
             this.dataForm.videoLabel = "HD";
             this.dataForm.webType = "video";
             this.dataForm.videoValue = 0;
             break;
            case 1:
             this.dataForm.videoOptions = [{value: 0, label: "HD"},{value: 1, label: "4K"}];
             this.dataForm.videoLabel = "HD";
             this.dataForm.webType = "video";
             this.dataForm.videoValue = 0;
              break;
            case 2:
              this.dataForm.videoOptions = [{value: 2, label: "严选HD"}];
              this.dataForm.videoLabel = "严选HD";
              this.dataForm.webType = "videoyx";
              this.dataForm.videoValue = 2;
              this.dataForm.price = result.priceList[2].price;
              break;
            case 3:
              this.dataForm.videoOptions = [{value: 2, label: "严选HD"},{value: 3, label: "严选4K"}];
              this.dataForm.videoLabel = "严选HD";
              this.dataForm.webType = "videoyx";
              this.dataForm.videoValue = 2;
              this.dataForm.price = result.priceList[2].price;
              break;
           }
        }

      }else{
        this.dataForm.id = result.recCode;
        this.dataForm.msg = result.msg;
        this.dataForm.type = false;
      }
      this.tableDatas.push(this.dataForm);
      this.tableLoading = false;
    },
    getTableDatas223(imageID){ 
      this.dataForm = {
        img: '', //缩略图
        id: imageID, //图片ID
        imgSize: "", //图片大小
        complete: false, //操作状态为按钮
        loading: false, //操作状态为加载
        saleType: "", //默认为空
        price: 0, //图片价格
        priceList:[], //视频价格
        videoType:0,//视频格式
        isfk:false,//是否4k
        isyx:false,//是否严选
        orgPrice:'',//原价
        balance: "", //用户余额
        value: 4, //图片类型默认值
        disabled: false, //是否可选
        videoValue: 0, //视频类型默认值
        videoLabel:'', //视频标签
        videoDisabled: false, //是否可选
        videoOptions: [], //视频类型
        options: [], //图片类型
        webType: this.webType, //网站类型
        isVideo:false, //是否视频
        downurl: "", //主下载链接
        downurl2: "", //备用下载链接
        downurl3: "", //海外下载链接
        type : false, //正常
        showType:true, //是否显示格式
        msg:'223开10位编号由于特殊原因，请联系上方客服下载', //提示消息
        isBuy:false, //是否购买过
        isCos:false, //是否腾讯云
        isDownload:false,//是否下载中
        isDownload1:false //是否备用下载中
      }

      this.tableDatas.push(this.dataForm);
      this.tableLoading = false;
    },
    //下载ID
    downloadId(flag){
      this.isSearching = flag;
      this.isDownloading = flag;
    },
    //改变下载状态
    changeDown(flag){
      this.isSureDownload = flag;
    },
    ///搜索ID
    async searchId(prams) {
      if(!this.isLoggedIn){
        message.error({
          message: '您还没登录用户，请登录',
          showClose: true,
          duration: 4000,
          offset: 100
        });
        return ;
      }
      this.isSearching = true;
      this.isClearFlag = true;
      this.isDownloading = true;
      this.isSureDownload = false;
      this.tableDatas = [];
      this.showTable = true;
      this.tableLoading = true;
      var len = 0;
      let mblance = this.$store.state.common.userInfo.blance;
      //根据余额限制搜索数量
      if(mblance<=50&&prams.length>30){//0-50元余额用户限制一次最多搜索30个
        message.error({
          message: '一次性最多只能搜索30个ID',
          showClose: true,
          duration: 4000,
          offset: 100
        });
        this.downloadId(false);
        this.showTable=false;
        this.tableLoading=false; //加载数据效果
        return;
      }else if(mblance<=100&&prams.length>70){//50-100元余额用户限制一次最多搜索70个
        message.error({
          message: '一次性最多只能搜索70个ID',
          showClose: true,
          duration: 4000,
          offset: 100
        });
        this.downloadId(false);
        this.showTable=false;
        this.tableLoading=false; //加载数据效果
        return;
      }else { // 100元以上用户不作限制

      }
      while (len < prams.length) {
        let dataform = {
          webType: this.webType,
          imageID: prams[len]
        };
        //判断是否223  10位
        if(dataform.imageID.startsWith('223') && dataform.imageID.length == 10 && (dataform.webType == 'shutterstock' || dataform.webType == 'shutterstock_V2')){ 
          this.getTableDatas223(dataform.imageID);
        }else{
          try{
            let res = await searchImage(dataform);
            if(res.recCode != null && res.recCode != '') {
              this.isNetError = false;
              this.getTableDatas(res);
            }else{
              let res = await searchImage(dataform);
              if(res.recCode != null && res.recCode != '') {
                this.isNetError = false;
                this.getTableDatas(res);
              }else{
                this.isNetError = true;
                this.tableLoading =false;
                message.error({
                  message: '网络异常，请重试',
                  showClose: true,
                  duration: 4000,
                  offset: 100
                });
              }
            }
          }catch(e){
            this.isNetError = true;
            message.error({
              message: '网络异常，请重试',
              showClose: true,
              duration: 4000,
              offset: 100
            });
          }
        }
        this.tableDatas.length > 0 ? (this.tableLoading = false) : (this.tableLoading = true);
        len++;
      }
      this.isSearching = false;
      this.isDownloading = false;
      this.isClearFlag = false;
      this.tableLoading = false;
    },
    noBanlances(){//余额不足
      this.tableDatas = [];
      this.isSureDownload = false;
    },

  }
};
var tawks1,tawks0;
(function(){
  //console.log("test111","embed.tawk.to");
  tawks1=document.createElement("script"),tawks0=document.getElementsByTagName("script")[0];
  //放到onMounted里面设置，不然没法设置用户名
  // tawks1.async=true;
  // tawks1.src='https://embed.tawk.to/5e883faf69e9320caac026bc/default';
  // tawks1.charset='UTF-8';
  // tawks1.setAttribute('crossorigin','*');
  //tawks0.parentNode.insertBefore(tawks1,tawks0);
})();

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
