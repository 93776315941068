<template>
  <div class="tabs">
    <ul class="list">
      <li
        class="item"
        v-for="(item,index) in tablist"
        :key="index"
        :class="index == current ? 'active' : ''"
        @click="change(index)">
        <img class="iconfont" :src="item.icon" alt  />
        <span class="label">{{item.title}}</span>
      </li>
      <li class="item" @click="popServerDialog">
        <img class="iconfont" :src="iconitem" alt  />
        <span class="label">SS/Hai 223开图片</span>
      </li>
    </ul>
    <el-button class="cost-detail" type="primary" plain @click="constDetail">费用明细</el-button>
    <el-button class="account-detail" type="primary" plain @click="accountDetail">查看余额</el-button>

  </div>
</template>

<script>
  export default {
  props: ["tabCurrent","tablist"],
  computed: {
    current() {
      return this.tabCurrent;
    }
  },
  data() {
    return {
      //tabList: []
      iconitem:require("@/assets/images/download/ic1_1.png")
    };
  },
  methods: {
    change(index) {
      this.$emit("change", index);
    },
    //弹窗提示客服下载
    popServerDialog(){
      this.$emit("serverDialog");
    },
    //费用明细
    constDetail() {
      this.$emit("constDetail");
    },
    //费用明细
    accountDetail() {
      this.$emit("accountDetail");
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
$tabItemBg: #fff; //f8f8fb
.tabs {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  .list {
    float: left;
    width: calc(100% - 110px);
    overflow: hidden;
    .item {
      margin: 0 18px 10px 0;
      width: 200px;
      height: 38px;
      line-height: 38px;
      display: inline-block;
      color: #333;
      background-color: #f3f4f9;
      border-radius: 100px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      &.active {
        color: #0773fc !important;
      }
      &:last-child {
        .line {
          display: none;
        }
      }
      .iconfont {
        margin-right: 5px;
        vertical-align: middle;
      }
      .label {
        vertical-align: middle;
      }
    }
    .item-last {
      margin: 0 18px 10px 0;
      width: 200px;
      height: 38px;
      line-height: 38px;
      display: inline-block;
      color: #333;
      background-color: #f3f4f9;
      border-radius: 100px;
      font-size: 14px;
      text-align: center;
      cursor: pointer;
      &.active {
        color: #0773fc !important;
      }
    }
  }
  .cost-detail {
    position: absolute;
    right: 0;
    top: 0;
  }
  .account-detail {
    position: absolute;
    right: 0;
    top: 50px;
  }
  .account-kefu {
    position: absolute;
    right: 0;
    top: 100px;
  }
}
</style>
